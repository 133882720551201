var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.usuarioDB.data.mods.includes(8) || _vm.usuarioDB.data.role == 'ADMIN')?_c('div',[_c('v-card-text',{staticClass:"m-0 pt-0"},[_c('span',{staticClass:"subheading"},[_vm._v("Seleccione Año")]),_c('v-chip-group',{attrs:{"active-class":"primary","mandatory":""},model:{value:(_vm.anoSeleccionado),callback:function ($$v) {_vm.anoSeleccionado=$$v},expression:"anoSeleccionado"}},_vm._l((_vm.aniosArraySort),function(ano){return _c('v-chip',{key:ano.ano,attrs:{"value":ano.ano}},[_vm._v(" "+_vm._s(ano.ano)+" ")])}),1)],1),_c('h5',{staticClass:"font-weight-light pb-3"},[_vm._v("Facturación")]),_c('v-sheet',{staticClass:"mx-auto transparent",attrs:{"elevation":"0"}},[_c('v-slide-group',{staticClass:"pa-4",attrs:{"active-class":"white","show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-4 elevation-2",attrs:{"color":active ? 'red' : 'red',"height":"200","width":"200"},on:{"click":toggle}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":"red"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-bell-alert-outline ")])],1)],1),_c('v-card-text',{staticClass:"white--text",attrs:{"align":"center"}},[_c('h4',[_vm._v(_vm._s(_vm.fact_pendientes))])]),_c('v-card-text',{staticClass:"white--text",attrs:{"align":"center"}},[_c('h6',[_vm._v("Conceptos sin facturar")])])],1)]}}],null,false,3737280986)}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-4 elevation-2",attrs:{"color":active ? 'green' : 'green',"height":"200","width":"200"},on:{"click":toggle}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":"green"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-currency-usd ")])],1)],1),_c('v-card-text',{staticClass:"white--text",attrs:{"align":"center"}},[_c('h4',[_vm._v("$"+_vm._s(_vm.fact_facturado.toLocaleString("es-CL")))])]),_c('v-card-text',{staticClass:"white--text",attrs:{"align":"center"}},[_c('h6',[_vm._v("Total facturado mes")])])],1)]}}],null,false,2651539979)}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-4 elevation-2",attrs:{"color":active ? 'verde' : 'verde',"height":"200","width":"200"},on:{"click":toggle}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":"verde"}},[_c('v-icon',[_vm._v(" mdi-currency-usd ")])],1)],1),_c('v-card-text',{staticClass:"primary--text",attrs:{"align":"center"}},[_c('h4',[_vm._v("$"+_vm._s(_vm.fact_pagado.toLocaleString("es-CL")))])]),_c('v-card-text',{staticClass:"primary--text",attrs:{"align":"center"}},[_c('h6',[_vm._v("Total pagado mes")])])],1)]}}],null,false,230736064)})],1),_c('Bar',{attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartData,"chart-id":_vm.chartId,"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.styles,"width":_vm.width,"height":_vm.height}})],1)],1):_vm._e(),_c('br'),_c('br'),(_vm.usuarioDB.data.mods.includes(5) || _vm.usuarioDB.data.role == 'ADMIN')?_c('div',[_c('h5',{staticClass:"font-weight-light pb-3"},[_vm._v("Presupuestos")]),_c('v-sheet',{staticClass:"mx-auto transparent",attrs:{"elevation":"0"}},[_c('v-slide-group',{staticClass:"pa-4",attrs:{"active-class":"white","show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-4 elevation-2",attrs:{"color":active ? 'green lighten-3' : 'green lighten-3',"height":"200","width":"200"},on:{"click":toggle}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":"green"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-briefcase-check-outline ")])],1),_c('v-spacer'),_c('h6',{staticClass:"white--text"},[_vm._v("Autorizados")])],1),_c('v-card-text',{staticClass:"white--text ml-2"},[_c('h6',[_vm._v("Cantidad: "+_vm._s(_vm.pres_aprobados))]),_c('h6',[_vm._v("U.F: "+_vm._s(_vm.pres_aprobados_uf.toLocaleString("es-CL")))]),_c('h6',[_vm._v("Pesos: $"+_vm._s(_vm.pres_aprobados_pesos.toLocaleString("es-CL")))])])],1)]}}],null,false,2695663015)}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-4 elevation-2",attrs:{"color":active ? 'orange lighten-2' : 'orange lighten-2',"height":"200","width":"200"},on:{"click":toggle}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":"orange"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-briefcase-clock-outline ")])],1),_c('v-spacer'),_c('h6',{staticClass:"white--text"},[_vm._v("Pendientes")])],1),_c('v-card-text',{staticClass:"white--text ml-2"},[_c('h6',[_vm._v("Cantidad: "+_vm._s(_vm.pres_pendientes))]),_c('h6',[_vm._v("U.F: "+_vm._s(_vm.pres_pendientes_uf.toLocaleString("es-CL")))]),_c('h6',[_vm._v("Pesos: $"+_vm._s(_vm.pres_pendientes_pesos.toLocaleString("es-CL")))])])],1)]}}],null,false,2073838370)}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-4 elevation-2",attrs:{"color":active ? 'red lighten-3' : 'red lighten-3',"height":"200","width":"200"},on:{"click":toggle}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":"red"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-briefcase-remove-outline ")])],1),_c('v-spacer'),_c('h6',{staticClass:"white--text"},[_vm._v("Rechazados")])],1),_c('v-card-text',{staticClass:"white--text ml-2"},[_c('h6',[_vm._v("Cantidad: "+_vm._s(_vm.pres_rechazados.toLocaleString("es-CL")))]),_c('h6',[_vm._v("U.F: "+_vm._s(_vm.pres_rechazados_uf.toLocaleString("es-CL")))]),_c('h6',[_vm._v("Pesos: $"+_vm._s(_vm.pres_rechazados_pesos.toLocaleString("es-CL")))])])],1)]}}],null,false,523726098)})],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }