<template>
  <div>
    <div v-if="usuarioDB.data.mods.includes(8) || usuarioDB.data.role == 'ADMIN'">
      <v-card-text class="m-0 pt-0">
        <span class="subheading">Seleccione Año</span>
        <v-chip-group
          v-model="anoSeleccionado"
          active-class="primary"
          mandatory
        >
          <v-chip v-for="ano in aniosArraySort" :key="ano.ano" :value="ano.ano">
            {{ ano.ano }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <h5 class="font-weight-light pb-3">Facturación</h5>
      <v-sheet class="mx-auto transparent" elevation="0">
        <v-slide-group v-model="model" class="pa-4" active-class="white" show-arrows>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'red' : 'red'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle"
            >
              <v-card-title>
                <v-avatar color="red">
                  <v-icon dark> mdi-bell-alert-outline </v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="white--text" align="center">
                <h4>{{ fact_pendientes }}</h4>
              </v-card-text>
              <v-card-text class="white--text" align="center">
                <h6>Conceptos sin facturar</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'green' : 'green'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle"
            >
              <v-card-title>
                <v-avatar color="green">
                  <v-icon dark> mdi-currency-usd </v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="white--text" align="center">
                <h4>${{ fact_facturado.toLocaleString("es-CL") }}</h4>
              </v-card-text>
              <v-card-text class="white--text" align="center">
                <h6>Total facturado mes</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'verde' : 'verde'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle"
            >
              <v-card-title>
                <v-avatar color="verde">
                  <v-icon> mdi-currency-usd </v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="primary--text" align="center">
                <h4>${{ fact_pagado.toLocaleString("es-CL") }}</h4>
              </v-card-text>
              <v-card-text class="primary--text" align="center">
                <h6>Total pagado mes</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </v-sheet>
    </div>
    <br><br>
    <div v-if="usuarioDB.data.mods.includes(5) || usuarioDB.data.role == 'ADMIN'">
      <h5 class="font-weight-light pb-3">Presupuestos</h5>
      <v-sheet class="mx-auto transparent" elevation="0">
        <v-slide-group v-model="model" class="pa-4" active-class="white" show-arrows>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'green lighten-3' : 'green lighten-3'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle"
            >
              <v-card-title>
                <v-avatar color="green">
                  <v-icon dark> mdi-briefcase-check-outline </v-icon>
                </v-avatar>
                <v-spacer></v-spacer>
                <h6 class="white--text">Autorizados</h6>
              </v-card-title>

              <v-card-text class="white--text ml-2">
                <h6>Cantidad: {{ pres_aprobados }}</h6>
                <h6>U.F: {{ pres_aprobados_uf.toLocaleString("es-CL") }}</h6>
                <h6>Pesos: ${{ pres_aprobados_pesos.toLocaleString("es-CL") }}</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'orange lighten-2' : 'orange lighten-2'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle"
            >
              <v-card-title>
                <v-avatar color="orange">
                  <v-icon dark> mdi-briefcase-clock-outline </v-icon>
                </v-avatar>
                <v-spacer></v-spacer>
                <h6 class="white--text">Pendientes</h6>
              </v-card-title>

              <v-card-text class="white--text ml-2">
                <h6>Cantidad: {{ pres_pendientes }}</h6>
                <h6>U.F: {{ pres_pendientes_uf.toLocaleString("es-CL") }}</h6>
                <h6>Pesos: ${{ pres_pendientes_pesos.toLocaleString("es-CL") }}</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'red lighten-3' : 'red lighten-3'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle"
            >
              <v-card-title>
                <v-avatar color="red">
                  <v-icon dark> mdi-briefcase-remove-outline </v-icon>
                </v-avatar>
                <v-spacer></v-spacer>
                <h6 class="white--text">Rechazados</h6>
              </v-card-title>

              <v-card-text class="white--text ml-2">
                <h6>Cantidad: {{ pres_rechazados.toLocaleString("es-CL") }}</h6>
                <h6>U.F: {{ pres_rechazados_uf.toLocaleString("es-CL") }}</h6>
                <h6>Pesos: ${{ pres_rechazados_pesos.toLocaleString("es-CL") }}</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


//Definimos la clase de los elementos

export default {
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre'
        ],
        datasets: [
          {
            label: 'Facturación',
            backgroundColor: '#f87979',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
          {
            label: 'Pagos de factura',
            backgroundColor: '#2A235C',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      facturas: [],
      resultado_facturas: [],
      loading: true,
      pres_aprobados: 0,
      pres_aprobados_pesos: 0,
      pres_aprobados_uf: 0,
      pres_pendientes: 0,
      pres_pendientes_pesos: 0,
      pres_pendientes_uf: 0,
      pres_rechazados: 0,
      pres_rechazados_pesos: 0,
      pres_rechazados_uf: 0,

      fact_facturado: 0,
      fact_pagado: 0,
      fact_pendientes: 0,

      aniosDisponibles: [],
      anoSeleccionado: new Date().getFullYear(),
    };
  },

  computed: {
    ...mapState(["token", "usuarioDB"]),
    aniosArraySort: function () {
      function compare(a, b) {
        if (a.ano < b.ano) return 1;
        if (a.ano > b.ano) return -1;
        return 0;
      }

      return this.aniosDisponibles.sort(compare);
    },
  },
  methods: {
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      axios.get("/users/", config).then((response) => {
        this.desserts = response.data;
        this.loading = false;
      });
    },
    async getDataPresupuestos() {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        let filtro = this.$options.filters;

        axios.get("/presupuestos/presupuesto/", config).then((response) => {
          var dataResponse = response.data;
          console.log(response.data);

          for (const arr of response.data) {
            let date = new Date(Date.parse(arr.fecha_envio));
            let year = date.getFullYear();
            var existeAno = this.aniosDisponibles.findIndex((xa) => xa.ano == year);
            if (existeAno === -1) this.aniosDisponibles.push({ ano: String(year) });
          }

          //console.log(date.getFullYear())

          this.pres_aprobados = dataResponse.filter(
            (e) => e.status == "2" && e.fecha_envio.includes(this.anoSeleccionado)
          ).length;
          this.pres_pendientes = dataResponse.filter(
            (e) => e.status == "1" && e.fecha_envio.includes(this.anoSeleccionado)
          ).length;
          this.pres_rechazados = dataResponse.filter(
            (e) => e.status == "3" && e.fecha_envio.includes(this.anoSeleccionado)
          ).length;

          this.pres_aprobados_uf = dataResponse
            .filter(
              (e) => e.status == "2" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "1")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_aprobados_pesos = dataResponse
            .filter(
              (e) => e.status == "2" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "2")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_pendientes_uf = dataResponse
            .filter(
              (e) => e.status == "1" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "1")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_pendientes_pesos = dataResponse
            .filter(
              (e) => e.status == "1" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "2")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_rechazados_uf = dataResponse
            .filter(
              (e) => e.status == "3" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "1")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_rechazados_pesos = dataResponse
            .filter(
              (e) => e.status == "3" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "2")
            .reduce((acu, actual) => acu + actual.valor, 0);

          resolve("ok");
        });
      });
    },
    async getDataFacturas() {
      new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        let filtro = this.$options.filters;

        axios.get("/facturas/factura/", config).then((response) => {
          var dataResponse = response.data;
          this.facturas = response.data;

          const timeElapsed = Date.now();
          const today = new Date(timeElapsed);

          let hoy = today.toISOString().substr(0, 7);

          this.fact_facturado = dataResponse
            .filter((e) => e.fecha_factura.substr(0, 7) == hoy && e.status != 3)
            .reduce((acu, actual) => acu + actual.monto, 0);
            this.facturacionAnio();

          this.fact_pagado = dataResponse
            .filter((e) => e.fecha_factura.substr(0, 7) == hoy && e.status == 2)
            .reduce((acu, actual) => acu + actual.monto, 0);
            this.facturacionAnio();
          //console.log(rsg);

          resolve("ok");
        });
      });
    },
    async facturacionAnio(){
      //this.anoSeleccionado
      const facturado_ano = [];
      const pagado_ano = [];
      for(let i = 1; i <=12; i++){

        const armaFecha = (i < 10) ? `${this.anoSeleccionado}-0${i}` : `${this.anoSeleccionado}-${i}`;
        
        const busca_mesano = this.facturas
            .filter((e) => e.fecha_factura.substr(0, 7) == armaFecha && e.status != 3)
            .reduce((acu, actual) => acu + actual.monto, 0);
        facturado_ano.push(busca_mesano);
      
      const busca_mesano_pagado = this.facturas
            .filter((e) => e.fecha_factura.substr(0, 7) == armaFecha && e.status == 2)
            .reduce((acu, actual) => acu + actual.monto, 0);
        pagado_ano.push(busca_mesano_pagado);
      }

      this.chartData.datasets = [
          {
            label: 'Facturación',
            backgroundColor: '#f87979',
            data: facturado_ano
          },
          {
            label: 'Pagos de factura',
            backgroundColor: '#2A235C',
            data: pagado_ano
          }
        ]
      //console.log(facturado_ano);
      //console.log(pagado_ano);



    },
    async getDataPendientes() {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        let filtro = this.$options.filters;

        axios.get("/proyectos/todosSeguimientos/", config).then((response) => {
          //console.log()
          console.log(response);
          var dataResponse = response.data;
          var responsefinal = [];
          for (let dato in dataResponse) {
            for (let concepto of dataResponse[dato].condiciones) {
              responsefinal.push({
                _id: dataResponse[dato]._id,

                //nro_factura: filtro.fecha_normal(concepto.fecha_realizacion),
                status: concepto.estado,
              });
            }
          }
          this.fact_pendientes = responsefinal.filter((e) => e.status == 2).length;
          //console.log(responsefinal);

          this.loading = false;
        });
      });
    },
  },
  async created() {
    //this.getData();
    await this.getDataPresupuestos();
    await this.getDataFacturas();
    await this.getDataPendientes();
  },
  watch: {
    anoSeleccionado(v) {
      //this.verPagos = 0;
      this.getDataPresupuestos();
      this.getDataFacturas();
      this.getDataPendientes();
    },
  },
};
</script>
<style>
.card-actions {
  position: absolute;
  bottom: 0;
  width: "100%";
}
</style>
